import * as React from "react";
import CalloutBanner from "../components/fullWidthBanner/callout/index.js";
import Gallery from "../components/gallery/index.js";
import HeroComponent from "../components/hero/index.js";
import Layout from "../components/Layout.js";
import UpcomingEvent from "../components/upcomingEvent/index.js";
import image from "../images/events/beachday.jpeg";

const events = [
  {
    name: "Beach Day!",
    image: image,
  },
  {
    name: "Beach Day!",
    image: image,
  },
  {
    name: "Beach Day!",
    image: image,
  },
];

const Events = () => {
  return (
    <Layout>
      <HeroComponent
        page={"Events"}
        heading={"What do we do?"}
        subheading={
          "Check out our past events, as well as what we have in store next."
        }
      ></HeroComponent>
      <CalloutBanner
        heading={"Coming up next..."}
        bgColor={"bg-blue"}
      ></CalloutBanner>
      <UpcomingEvent></UpcomingEvent>
      <CalloutBanner
        heading={"Past events"}
        bgColor={"bg-white"}
      ></CalloutBanner>
      <Gallery
        events={events}
        cardColour={"bg-blue"}
        eventType={"Professional"}
      ></Gallery>
      <Gallery
        events={events}
        cardColour={"bg-skyBlue"}
        eventType={"Competitions"}
      ></Gallery>
      <Gallery
        events={events}
        cardColour={"bg-darkBlue"}
        eventType={"Socials"}
      ></Gallery>
    </Layout>
  );
};

export default Events;
