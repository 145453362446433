import * as React from "react";
// import image from "../../../../images/events/beachday.jpeg";

import image from "../../images/events/beachday.jpeg";
import Calendar from "./calendar";

const event = {
  name: "Beach Day!",
  day: "19",
  month: "Feb",
  description:
    "Come down to Bondi Beach for a swim , followed by a picnic with the COMM-STEM family. Chat with the execs & members, learn about what we do, and meet people doing similar degrees over pizza, snacks, and drinks! 🍹🍕",
  link: "https://www.facebook.com/events/700491577982269?acontext=%7B%22event_action_history%22%3A[%7B%22surface%22%3A%22page%22%7D]%7D",
};

const UpcomingEvent = () => {
  return (
    <div className="flex flex-col lg:flex-row items-center px-5 py-10 md:px-10 md:py-20 lg:px-24 xl:px-32">
      <div className="md:w-[100%] lg:w-1/2 ">
        <img src={image} alt={event.name} className="rounded-xl"></img>
      </div>
      <div className="flex flex-col pt-10 lg:pt-0 lg:pl-10 xl:pl-16 md:w-[100%] lg:w-1/2">
        <div className="flex flex-row items-center">
          <Calendar day={event.day} month={event.month}></Calendar>
          <h2 className="font-bold font-body text-4xl xl:text-5xl pl-8">
            {event.name}
          </h2>
        </div>
        <p className="font-body pt-8 lg:pt-5 xl:pt-10 xl:text-xl">
          {event.description}
        </p>
        <a
          href={event.link}
          target="_blank"
          className="mt-8 lg:mt-5 xl:mt-10 w-[9em] xl:1/12 inline-flex justify-center items-center px-3.5 py-2 font-bold font-lg font-lexend-deca rounded-full text-white bg-blue"
        >
          Sign me up!
        </a>
      </div>
    </div>
  );
};

export default UpcomingEvent;
